import React, { useState, useEffect } from "react";
import * as EmailValidator from 'email-validator';


import './weighted_quiz.css';


import {questions, outcomeDetails} from "./questions";

import WorkingCard from "./WorkingCard";
import Dropdown from "./mini-components/Dropdown"




//react components using the weighted quiz elements have
// params @ the callback function should know which element is it calling 
// Google chorme has stuff that you can add to you objects and add more items to

const WeightedQuiz = () => {

    //preloading images
    useEffect(() => {
        const pictures = [
            "/images/illustrations/accommodation-illustrations-live-with-18-25@2x.png",
            "/images/illustrations/accommodation-illustrations-lived-out-of-home-before@2x.png",
            "/images/illustrations/accommodation-illustrations-living-with-other-people@2x.png",
            "/images/illustrations/accommodation-illustrations-living-with-aus-family@2x.png",
            "/images/illustrations/accommodation-illustrations-provider-cook-you-food@2x.png",
            "/images/illustrations/accommodation-illustrations-share-bathroom@2x.png",
            "/images/illustrations/accommodation-illustrations-tutoring@2x.png",
            "/images/illustrations/accommodation-illustrations-social-activities@2x.png",
            "/images/illustrations/accommodation-illustrations-transport-option@2x.png",
            "/images/illustrations/accommodation-illustrations-some-of-the-cleaning@2x.png",
            "/images/illustrations/accommodation-illustrations-funished-when-you-arrive@2x.png",
            "/images/illustrations/accommodation-illustrations-kid-to-look-after@2x.png",
            "/images/illustrations/accommodation-illustrations-partner-to-live-with@2x.png",
        ]
        pictures.forEach((picture) => {
            
            const img = new Image();
            img.src = picture;
        });
    });

    //save use details in this state
    const [userDetails, setUserDetails] = useState({
        nationality: "Australia"
    });

    function userFromChangeHandler(e){
        let name = e.target.name;
        let value = e.target.value
        setUserDetails({
            ...userDetails,
            [name] : value
        })
    }


    //block for handling different pages
    var maxPage = questions.length - 1;
    // const [page, setPage] = useState("landing");
    const [page, setPage] = useState(0);

    function next(){
        if(page<maxPage)
            setPage(page+1);
        else if(page === maxPage){
            setPage("register");
        }
    }

    function prev(){
        if(page>0)
            setPage(page-1)
    }


    //consent toggler
    const [consentState, setConsentState] = useState(false)

    function consentToggler(){
        setConsentState(!consentState)
    }

    //form validation
    const [formMessage, setFromMessage] = useState("")


    function formValidator(){
        if(!userDetails.firstName || !userDetails.nationality)
            setFromMessage("Please enter you first name ");
        else if(!userDetails.lastName)
            setFromMessage("Please enter you last name ");
        // else if(!userDetails.email)
        else if(!EmailValidator.validate(userDetails.email)) // true
            setFromMessage("Please enter a valid email ");
        else if(!userDetails.nationality)
            setFromMessage("Please select your nationality ");

        else if(!consentState)
            setFromMessage("Please agree to our privacy policy")
        
        else{
            setPage("results");

        }
            
    }


    


    //state for saving choices
    const [currentChoice, setChoice] = useState({});

    //state for saving weights
    const [currentWeight, setWeight] = useState({});


    //useEffect for fetch api calls
    // useEffect(() => {
    //     // Update the document title using the browser API
    //     document.title = page;
    //   });

    
    if(page === "landing"){
        return(
            <>
                
               
                
                <div className= "landing animated animatedFadeInUp fadeInUp">
                    {/* <div className="hero-logo">
                        <img src="/images/Insider_Transparent@2x.png" alt="not found" />
                    </div> */}
                    <div className="hero-img">
                        <img src="/images/Homebase@2x.png" alt="not found" />
                    </div>

                    <div className="hero-text">
                        Designed specifically for international students coming to Australia, use this tool to discover what kind of accommodation matches your preferences.
                    </div>
                    
                    <div className="button-wrapper" style={{display : "flex", justifyContent:"center"}}>

                        <button className="start-btn" onClick={()=>{setPage(0)}}>Get Started</button>
                    </div>

                    <div className="footer-wrapper">
                        <div className="footer">
                            <a href="https://insiderguides.com.au/">
                                <div className="logo">
                                    <img src="/images/Insider_Transparent@2x.png" alt="not found" />
                                </div>
                            </a>
                            
                            <div className="links">
                                <div className="link-top">
                                    <li><a style={{textDecoration : "none", color: "white"}} href="https://insiderguides.com.au/privacy-policy/">Terms of Use</a></li>
                                    <li><a style={{textDecoration : "none", color: "white"}} href="https://insiderguides.com.au/privacy-policy/">Privacy Policy</a> </li>
                                </div>
                                <div className="link-bottom">
                                    <li>Insider Guides</li>
                                    <li>South Australia</li>
                                    <li>Copyright 2019</li>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                
                
            </>
        )
       
    }


    if(page === "register"){
        return(
            <>
            {/* <div className="navbar">
                <quiz-title>Accommodation Quiz</quiz-title>
                
            </div> */}
            <div className= "container animated animatedFadeInUp fadeInUp"  >
                <h2>Please enter your details</h2>
                <div className="user-info">
                    <form autoComplete="off">
                        <form-element>
                            <label>First name:</label>
                            <input type="text" id="firstName" name="firstName" value={userDetails.firstName || ""} onChange={userFromChangeHandler}/>
                        </form-element>
                        <form-element>
                            <label>Last name:</label>
                            <input type="text" id="lastName" name="lastName" value={userDetails.lastName || ""} onChange={userFromChangeHandler}/>
                        </form-element>
                        <form-element>
                            <label>Email:</label>
                            <input type="text" id="email" name="email" value={userDetails.email || ""} onChange={userFromChangeHandler}/>
                        </form-element>
                        
                        <form-element>
                            <label>Nationality:</label>
                            <select id="nationality" name="nationality" value={userDetails.nationality || ""} onChange={userFromChangeHandler}>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire">Bonaire</option>
                                <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Canary Islands">Canary Islands</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Channel Islands">Channel Islands</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">Christmas Island</option>
                                <option value="Cocos Island">Cocos Island</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote DIvoire">Cote DIvoire</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curaco">Curacao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="East Timor">East Timor</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands">Falkland Islands</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="French Southern Ter">French Southern Ter</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Great Britain">Great Britain</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="India">India</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea North">Korea North</option>
                                <option value="Korea South">Korea South</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Midway Islands">Midway Islands</option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Nambia">Nambia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherland Antilles">Netherland Antilles</option>
                                <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                <option value="Nevis">Nevis</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau Island">Palau Island</option>
                                <option value="Palestine">Palestine</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Phillipines">Philippines</option>
                                <option value="Pitcairn Island">Pitcairn Island</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Republic of Montenegro">Republic of Montenegro</option>
                                <option value="Republic of Serbia">Republic of Serbia</option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="St Barthelemy">St Barthelemy</option>
                                <option value="St Eustatius">St Eustatius</option>
                                <option value="St Helena">St Helena</option>
                                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                <option value="St Lucia">St Lucia</option>
                                <option value="St Maarten">St Maarten</option>
                                <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                <option value="Saipan">Saipan</option>
                                <option value="Samoa">Samoa</option>
                                <option value="Samoa American">Samoa American</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Tahiti">Tahiti</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Erimates">United Arab Emirates</option>
                                <option value="United States of America">United States of America</option>
                                <option value="Uraguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Vatican City State">Vatican City State</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                <option value="Wake Island">Wake Island</option>
                                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zaire">Zaire</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                        </form-element>
                        {/* <form-element>
                            <label>City of Interest:</label>
                            <select id="city" name="city" value={userDetails.city || ""} onChange={userFromChangeHandler}>
                                <option value="Adelaide">Adelaide</option>
                                <option value="Melbourne">Melbourne</option>
                                <option value="Sydney">Sydney</option>
                                <option value="Darwin">Darwin</option>
                                <option value="Brisbane">Brisbane</option>
                                <option value="Canberra">Canberra</option>
                                <option value="Perth">Perth</option>
                                <option value="Hobart">Hobart</option>
                                <option value="Gold Coast">Gold Coast</option>
                                <option value="Geelong">Geelong</option>
                                <option value="Newcastle">Newcastle</option>
                                <option value="Sunshine Coast">Sunshine Coast</option>
                            </select>
                        </form-element>
                        <form-element>
                            <label>Course of Interest:</label>
                            <select id="course" name="course" value={userDetails.course || ""} onChange={userFromChangeHandler}>
                                <option value="Arts">Arts</option>
                                <option value="Engineering & IT">Engineering & IT</option>
                                <option value="Health and Medicine">Health and Medicine</option>
                                <option value="Business, Science">Business, Science</option>
                                <option value="Architecture/Building & Planning">Architecture/Building & Planning</option>
                                <option value="Law">Law</option>
                                <option value="Education">Education</option>
                            </select>
                        </form-element> */}

                    
                        <div className="form-element">
                            <input type="checkbox" id="consent" name="consent" value="yes" onChange={consentToggler} style={{height: "1.5em", width: "1.5em", accentColor: "var(--accent-color"}}/>
                            <label htmlFor="consent">By clicking 'See Results' you agree to our <span><a style={{color: "white" }} href="https://insiderguides.com.au/privacy-policy/">Privacy Policy</a></span></label>
                            
                        </div>
                        
                    </form>
                    {/* <button className="orange-btn" onClick={()=>{setPage("results")}}>See Results</button> */}
                    <button className="orange-btn" onClick={formValidator}>See Results</button>

                    <h5 style={{margin : "0 0 3em 0"}}>{formMessage}</h5>

                </div>
            </div>
            <div className="footer-wrapper">
                <div className="footer">
                    <a href="https://insiderguides.com.au/">
                        <div className="logo">
                            <img src="/images/Insider_Transparent@2x.png" alt="not found" />
                        </div>
                    </a>
                    
                    <div className="links">
                        <div className="link-top">
                            <li><a style={{textDecoration : "none", color: "white"}} href="https://insiderguides.com.au/privacy-policy/">Terms of Use</a></li>
                            <li><a style={{textDecoration : "none", color: "white"}} href="https://insiderguides.com.au/privacy-policy/">Privacy Policy</a> </li>
                        </div>
                        <div className="link-bottom">
                            <li>Insider Guides</li>
                            <li>South Australia</li>
                            <li>Copyright 2019</li>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    if(page >= 0 && page<=maxPage ){
            return(
                <div>
                    {/* <div className="navbar">
                        <quiz-title onClick={()=>{setPage("landing")}}>Accommodation Quiz</quiz-title>
                        <page-no>{page+1}/{maxPage+1}</page-no>
                    </div> */}

                    <div className="snapping-container">
                            <div className="navbar">
                                {/* <quiz-title style={{fontSize:"2em", fontFamily: "Brandon-bold"}} onClick={()=>{setPage("landing")}}>Insider Guides</quiz-title> */}
                                <page-no style={{fontSize:"2em", fontFamily: "Brandon-bold"}}>{page+1}/{maxPage+1}</page-no>
                                <i className="fas fa-redo fa-lg" onClick={()=>{setPage("landing")}} style={{cursor : "pointer"}}></i>
                            </div>
                            <div className="progress-bar-wrapper" style={{backgroundColor: 'var(--accent-color)'}}>
                                <div className="progress bar">
                                    <div style={{height: "0.5em",width:`${(page+1)/(maxPage+1)*100}%`, backgroundColor : 'var(--main-bg-color)', borderRadius : "0 5px 5px 0"}}></div>
                                </div>
                            </div>
                            <div className="question-wrapper">
                                <h4>{questions[page].question}</h4>
                            </div>
                            
                    </div>

                    

                    <div key={Math.random()} className="container animated animatedFadeInUp fadeInUp">
                        <div className="options-img-wrapper">

                            <options-section>
                                <div className="option-wrapper">
                                    {questions[page].options.map((currentOption, currentOptionIndex) => (
                                        <div className="option-element" key={page+"-"+currentOptionIndex} label={currentOption[0]} onClick={(e)=>{
                                                setWeight({
                                                    ...currentWeight,
                                                    [page] : currentOption[1],
                                                    
                                            })
                                                setChoice({
                                                    ...currentChoice,
                                                    [page] : {
                                                                question: questions[page].question,
                                                                selected : currentOption[0],
                                                                weight : currentOption[1],
                                                                info : questions[page].info
                                                            }
                                                });
                                                next();
                                            }}>
                                            {currentOption[0]}
                                        </div>
                                        
                                    ))}
                                </div>
                                
                                <i className="fas fa-arrow-circle-left" style={{fontSize: "2em", marginTop : "1em", cursor : "pointer"}} onClick={prev}></i>
                                
                            </options-section>
                            
                            <div className="question-img">
                                <img src={questions[page].img} alt="" />
                            </div>
                        
                        </div>
                        
                        
                        
                        
                    </div>
                    
                </div>
            )
        
    }

    if(page === "workings"){
        
        const choiceArray = Object.values(currentChoice);

        return(
            <div className="container">
                <h3>Hi {userDetails.firstName}, this is how we worked out the best accommodation for you..</h3>


                <h5 style={{fontSize : "1.5em", margin : "0 0 1em 0", fontWeight : "bold"}}>How to read this page :</h5>
                <h5 style={{fontSize : "1.25em", margin : "0 0 0.4em 0"}}><i  className="fas fa-check-circle fa-lg"></i> = This type of accommodation suits your needs based on your answer</h5>
                <h5 style={{fontSize : "1.25em", margin : "0 0 0.4em 0"}}><i  className="fas fa-exclamation-circle fa-lg"></i> = This might or might not work for you, please check the description</h5>
                <h5 style={{fontSize : "1.25em", margin : "0 0 0.4em 0"}}><i  className="fas fa-times-circle fa-lg"></i> = This type of accommodation does not fulfill your requirements</h5>

                {choiceArray.map((choice, choiceIndex)=>{
                    
                    return(
                        
                        
                        <WorkingCard key={choiceIndex} choice={choice}/>
                        
                    )
                    
                })}
                <div className="button-wrapper">
                    <button onClick={()=>{setPage("results")}}>Back to results</button>
                </div>
               
            </div>
        )
    }

    if(page === "results"){

        

        //fetch api
        function callApi(){
            

            console.log("fetch api called");
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        user : userDetails,
                        choices :  currentChoice
                    }
                )
            };
            fetch('/userDetails', requestOptions)
                .then(response => console.log(response))

        }

        callApi();
        
        //fetch api end


        //calculing the winner
        const weightArray = Object.values(currentWeight);
        console.log(weightArray);

        //initialize the array that would hold to total weights to 0
        var summedWeightArray = [];
        for(let i=0; i<Object.keys(outcomeDetails).length; i++){
            summedWeightArray[i] = 0;
        }

        //add all the wieghts of the of the selected options
        for(let i=0; i<weightArray.length; i++){
            for(let j=0; j<Object.keys(outcomeDetails).length; j++){
                summedWeightArray[j] = summedWeightArray[j] + weightArray[i][j];
            }
        }
        console.log("summed array is",summedWeightArray)

        


        //create a 2d array to hold the outcomes and the corresponding summed weights
        var finalScore2DArray = [];
        for(let i=0; i<Object.keys(outcomeDetails).length; i++){
            //remove the 3rd elment if it breaks======================
            finalScore2DArray[i] = [ outcomeDetails[Object.keys(outcomeDetails)[i]].title ,summedWeightArray[i], Object.keys(outcomeDetails)[i]];
            // console.log(outcomeDetails[Object.keys(outcomeDetails)[i]].title);
            console.log(Object.keys(outcomeDetails)[i]);
        }
        //sort the final scorred 2d array in descending order
        finalScore2DArray.sort(function(a,b){
            return b[1] - a[1];
        })
        console.log(finalScore2DArray);

        window.scrollTo(0, 0);

        return(
            <>

            <div className="container" style={{padding: "0 1em"}}>

                {/* <div className="navbar">
                    <quiz-title style={{fontSize:"2em", fontFamily: "Brandon-bold"}} onClick={()=>{setPage("landing")}}>Insider Guides</quiz-title>
                    <i class="fas fa-redo fa-lg" onClick={()=>{setPage("landing")}} style={{cursor : "pointer"}}></i>
                </div> */}
                {/* <div className="progress-bar-wrapper" style={{backgroundColor: 'var(--accent-color)'}}>
                    <div className="progress bar">
                        <div style={{height: "0.5em",width: "100%", backgroundColor : 'var(--main-bg-color)', borderRadius : "0 0 0 0"}}></div>
                    </div>
                </div> */}

                
                    <h3>Your Results</h3>
                

                

                
                    <div className = "result-card">
                        <img src={outcomeDetails[finalScore2DArray[0][2]].illustration} alt="" />
                        <h6 style={{margin: "1em 0 0 0"}}>You are most suited to:</h6>
                        <h4 style={{color : "var(--main-bg-color)", margin:  "0", fontWeight:"bold", fontSize: "2em"}}>{finalScore2DArray[0][0]}</h4>
                        <p>{outcomeDetails[finalScore2DArray[0][2]].excerpt}</p>
                        <div className="button-wrapper">
                            
                            {/* <Link to={'/' + finalScore2DArray[0][2]} target="_blank" rel="noopener noreferrer" >
                                <button>Learn more about this option</button>
                            </Link> */}
                    
                        </div>

                        <Dropdown item={outcomeDetails[finalScore2DArray[0][2]]}></Dropdown>
                        
                    </div>

                    
                    <div className = "result-card">
                        <img src={outcomeDetails[finalScore2DArray[1][2]].illustration} alt="" />
                        <h6 style={{margin: "1em 0 0 0"}}>Your second choice would be:</h6>
                        <h4 style={{color : "var(--main-bg-color)", margin:  "0", fontWeight:"bold", fontSize: "2em"}}>{finalScore2DArray[1][0]}</h4>
                        <p>{outcomeDetails[finalScore2DArray[1][2]].excerpt}</p>
                        {/* <div className="button-wrapper">
                            <Link to={'/' + finalScore2DArray[1][2]} target="_blank" rel="noopener noreferrer">
                                <button>Learn more about this option</button>
                            </Link>
                        </div> */}
                        <Dropdown item={outcomeDetails[finalScore2DArray[1][2]]}></Dropdown>
                    </div>
                    <div className="button-belt">
                        
                            <button style={{margin : "0"}}  onClick={()=>{setPage("workings")}}>How we worked this out</button>
                        
                        
                            <button  style={{margin : "0"}} onClick={()=>{setPage("landing")}}>Try Again</button>
                        

                    </div>
                    
                    
                

                
                

            </div>

            <div className="footer-wrapper" style={{backgroundColor: "var(--main-bg-color)"}}>
            <div className="footer" style={{backgroundColor: "var(--main-bg-color)"}}>
                <a href="https://insiderguides.com.au/">
                    <div className="logo">
                        <img src="/images/Insider_Transparent@2x.png" alt="not found" />
                    </div>
                </a>
                
                <div className="links">
                    <div className="link-top">
                        <li><a style={{textDecoration : "none", color: "white"}} href="https://insiderguides.com.au/privacy-policy/">Terms of Use</a></li>
                        <li><a style={{textDecoration : "none", color: "white"}} href="https://insiderguides.com.au/privacy-policy/">Privacy Policy</a> </li>
                    </div>
                    <div className="link-bottom">
                        <li>Insider Guides</li>
                        <li>South Australia</li>
                        <li>Copyright 2019</li>
                    </div>
                </div>
            </div>
        </div>

        </>
             
            
        )

    }


   

    
    
    
}

export default WeightedQuiz