const Stats = () => {

    return(
        <div className="container" >
            <h3>What other people picked</h3>
            <div className="chart-wrapper" >
                <iframe title="q1" style={{background: "#FFFFFF",border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)", width: "100%",  height: "480px" }} src="https://charts.mongodb.com/charts-project-0-hsyec/embed/charts?id=99cad7c7-e90c-47e5-b990-5c04fef154c0&maxDataAge=3600&theme=light&autoRefresh=false&attribution=false"></iframe>

                <iframe title="q2" style={{background: "#FFFFFF",border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)", width: "100%",  height: "480px"}}  src="https://charts.mongodb.com/charts-project-0-hsyec/embed/charts?id=5164b2f7-e728-473b-a675-a1f2f1844fe3&maxDataAge=3600&theme=light&autoRefresh=false&attribution=false"></iframe>

                <iframe title="q3" style={{background: "#FFFFFF",border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)", width: "100%",  height: "480px"}} src="https://charts.mongodb.com/charts-project-0-hsyec/embed/charts?id=27ff7182-257a-42a2-8a88-b12c4e5a3a57&maxDataAge=3600&theme=light&autoRefresh=false&attribution=false"></iframe>

                <iframe title="q4" style={{background: "#FFFFFF",border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)", width: "100%",  height: "480px"}}  src="https://charts.mongodb.com/charts-project-0-hsyec/embed/charts?id=9ec1c89b-82df-4eda-89ba-40033479afab&maxDataAge=3600&theme=light&autoRefresh=false&attribution=false"></iframe>

                <iframe title="q5" style={{background: "#FFFFFF",border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)", width: "100%",  height: "480px"}}  src="https://charts.mongodb.com/charts-project-0-hsyec/embed/charts?id=2c30cdee-3bef-4bad-8e9d-cb601b69190b&maxDataAge=3600&theme=light&autoRefresh=false&attribution=false"></iframe>

                <iframe title="q6" style={{background: "#FFFFFF",border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)", width: "100%",  height: "480px"}}  src="https://charts.mongodb.com/charts-project-0-hsyec/embed/charts?id=fc6d5e31-87ab-418b-8cb1-4a68acf2271e&maxDataAge=3600&theme=light&autoRefresh=false&attribution=false"></iframe>



            </div>
            
        </div>
    )
}

export default Stats