const outcomes = [
    "Private Rental Alone",
    "Private Rental With Housemates",
    "Purpose Built Student Accommodation",
    "Homestay",
    "Colleges"
]


const questions = [
    {
        //0
        img: "/images/illustrations/accommodation-illustrations-live-with-18-25@2x.png",
        question : "Will you be 18 when you start living in Australia?",
        options : [
            ["yes", [100, 100, 80, -100, 50]],
            ["no", [-100, -100, -50, 100, 100]]
        ],
        info : "If you're under 18, the Australian Government requires special accommodation arrangements to ensure your welfare and safety. Be sure to check out these requirements during your housing search."
    },

    {
        //1
        img: "/images/illustrations/accommodation-illustrations-lived-out-of-home-before@2x.png",
        question : "Have you ever lived out of home before?",
        options : [
            ["yes", [100, 100, 80, -100, 50]],
            ["no", [-100, -100, -50, 100, 100]]
        ],
        info :  "If this is your first time living away from home, you may want extra support (e.g., flatmates). But if you've lived away from home before, you may crave more independence."
    },

    {
        //2
        img: "/images/illustrations/accommodation-illustrations-living-with-other-people@2x.png",
        question : "Do you like living with other people?",
        options : [
            ["no, I do not", [200, -150, -100, -200, -100]],
            ["not really", [100, -50, -50, -50, -50]],
            ["I dont mind", [1, 1, 1, 1, 1]],
            ["I am open to it", [-50, 50, 50, 50, 50]],
            ["definitely, yes!", [-100, 100, 100, 100, 100]]
        ],
        info : "Some people love sharing their space with others and making new friends this way. But there are challenges, such as messy spaces, lack of alone time, and excessive noise."
    },

    {
        //3
        img: "/images/illustrations/accommodation-illustrations-living-with-aus-family@2x.png",
        question : "Would you like to live with an Australian family?",
        options : [
            ["no, I do not", [100, 100, 100, -100, 100]],
            ["not really", [100, 50, 50, -50, 50]],
            ["I dont mind", [1, 1, 1, 1, 1]],
            ["I am open to it", [-50, -50, -50, 50, -50]],
            ["definitely, yes!", [-100, -100, -100, 100, -100]],
        ],
        info: "Some students love the sense of support and how helpful a family can be. However, it may affect your independence, especially if you end up living far out of the city!"
    },


    {
        //4
        img: "/images/illustrations/accommodation-illustrations-provider-cook-you-food@2x.png",
        question : "Do you want your accommodation provider to cook your meals?",
        options : [
            ["no", [100, 100, 100, -100, -100]],
            ["I'd prefer they didn't", [50, 50, 50, -50, 50]],
            ["I dont mind", [1, 1, 1, 1, 1]],
            ["that would be nice", [-50, -50, -50, 50, 50]],
            ["yes, this is essential", [-100, -100, -100, 100, 100]],
        ],
        info : "Not very skilled in the kitchen? Accommodation that includes meals could be a great fit for you! That said, there's no guarantee that you'll like the food they serve."
    },

    {
        //5
        img: "/images/illustrations/accommodation-illustrations-share-bathroom@2x.png",
        question : "Are you ok to share a bathroom with other people?",
        options : [
            ["definitely not", [100, -100, -50, -50, -100]],
            ["I'd prefer not to", [100, -80, -50, -50, -100]],
            ["yeah, thats okay", [0, 100, 100, 100, 100]],
        ],
        info : "Sharing a bathroom means you'll likely have help cleaning it, creating less housework for you. But keep in mind that sharing a bathroom can often be tricky and messy."
    },

    {
        //6
        img: "/images/illustrations/accommodation-illustrations-tutoring@2x.png",
        question : "Do you want your accommodation provider to provide tutoring and academic support?",
        options : [
            ["no, I wouldn't use it", [1, 1, 1, 1, 1]],
            ["that would be nice", [-1, -1, 20, -1, 100]],
            ["yes, this is essential", [-100, -100, 50, -100, 200]],
        ],
        info : "Think you'll need tutoring? Having that aid built into your housing could be a huge advantage for you! But for those who won’t use it, it's an unnecessary added expense."
    },

    {
        //7
        img: "/images/illustrations/accommodation-illustrations-social-activities@2x.png",
        question : "Some accommodation options offer lifestyle extras like sports teams, barbecue facilities and organized social activities. How important is this to you?",
        options : [
            ["not at all important", [100, 100, -100, 100, -100]],
            ["not important", [50, 50, -50, 50, -50]],
            ["I dont mind", [1, 1, 1, 1, 1]],
            ["important", [-50, -50, 50, -50, 50]],
            ["very important", [-100, -100, 100, -100, 100]],
        ],
        info : "Certain students love these extras because they provide great opportunities for making new friends. But others prefer making friends in their courses or at their part-time jobs."
    },


    {
        //8
        img: "/images/illustrations/accommodation-illustrations-transport-option@2x.png",
        question : "What is your preferred transport option to your institution?",
        options : [
            ["walking", [20, 20, 50, 0, 100]],
            ["cycling", [50, 50, 30, 20, 100]],
            ["public transport", [100, 100, 80, 100, 50]],
            ["car", [100, 100, -50, 100, 0]],
        ],
        info : "If you're hoping to walk, you'll need accommodation that's close to campus, which can be costly. But if you live further away, you'll need to budget for public transport."
    },

    {
        //9
        img: "/images/illustrations/accommodation-illustrations-some-of-the-cleaning@2x.png",
        question : "Do you want your accommodation provider to look after some of the cleaning?",
        options : [
            ["no", [100, 100, -50, -100, -75]],
            ["prefer they didn't", [80, 80, 0, -50, -50]],
            ["I dont mind", [1, 1, 1, 1, 1]],
            ["that would be nice", [-50, -50, 50, 100, 50]],
            ["yes, this is essential", [-100, -100, 75, 100, 75]],
        ],
        info : "Having help with household cleaning can be ideal, especially for busy students! However, accommodation options with cleaning aid are often a bit pricier. (Please note that Purpose Built Student Accommodation offers limited cleaning options, and you're expected to keep your place tidy for the most part)"
    },

    {
        //10
        img: "/images/illustrations/accommodation-illustrations-funished-when-you-arrive@2x.png",
        question : "Do you want the place to be furnished when you arrive?",
        options : [
            ["no", [100, 100, -100, -100, -100]],
            ["I dont mind", [1, 1, 1, 1, 1]],
            ["yes please", [-100, -50, 100, 100, 100]],
        ],
        info : "Furniture is expensive, especially on a student budget! Furnished accommodation eliminates that hassle. However, because you didn’t choose it, you may not love the furniture you end up with."
    },

    {
        //11
        img: "/images/illustrations/accommodation-illustrations-kid-to-look-after@2x.png",
        question : "Do you have kids to look after at your accommodation?",
        options : [
            ["no", [50, 100, 100, 100, 100]],
            ["yes", [100, -50, -100, -100, -100]],
        ],
        info : "You may be bringing your children to Australia with you. While having your loved ones around you is wonderful, you'll likely need more accommodation space, which could get expensive."
    },

    {
        //12
        img: "/images/illustrations/accommodation-illustrations-partner-to-live-with@2x.png",
        question : "Do you have a partner you would like to live with?",
        options : [
            ["no", [50, 100, 100, 100, 100]],
            ["yes", [100, 50, -50, -100, -100]],
        ],
        info : "Living with a partner can be a great way of taking your relationship to the next level. But it can limit your accommodation options, especially if they aren't a student."
    },

    // {
    //     //13
    //     img: "/images/illustrations/accommodation-illustrations-live-with-18-25@2x.png",
    //     question : "Are you happy to live with 18-25 year olds?",
    //     options : [
    //         ["no", [100, 50, -100, 80, -100]],
    //         ["yes", [-50, 100, 100, 30, 100]],
    //     ],
    //     info : "If you fall in this age group, living with people aged 18-25 could be a perfect fit! However, for mature students, it may not be so desirable."
    // },
]


const outcomeDetails = {
    privateRentalAlone : {
        title : "Private Rental Alone",
        excerpt : "This is ultimate independence! When you rent privately on your own, you have a unit completely to yourself. You’ll have much more control but also face a higher price tag.",
        costs : "Private rentals for one person can cost anywhere from AU$280 to AU$815 per week. Your rent will depend on the city you live in, the home’s proximity to amenities, and its size.",
        features : [
            "Units may be furnished or unfurnished",
            "Utilities are typically not included",
            "Meals are not included",
            "Highest level of independence and freedom",
        ],
        issues : [
            "Living alone can be very expensive, especially for students",
            "You may need to live further away from your education provider to afford independent housing",
            "You may feel lonely or isolated at times"
        ],
        illustration : "/images/illustrations/accommodation-illustrations-private-rental-alone@2x.png",
        links : {
            link1 : {
                text : "How to Find a Private Rental",
                url : "https://insiderguides.com.au/find-private-rental-australia/",
            },
            link2 : {
                text : "Living in Student Accommodation vs. Private Rentals: The Pros and Cons",
                url : "https://insiderguides.com.au/living-in-student-accommodation-vs-private-rentals/",
            } 
        }

    },

    privateRentalWithHousemates : {
        title : "Private Rental With Housemates",
        excerpt : "The freedom of living independently, but without all the cost! When you rent privately with housemates, you’ll share your home with them. It’s a great way to cut costs while meeting new people!",
        costs : "You can expect to spend anywhere from AU$170 to AU$370 per week on a private rental with housemates. These costs will vary depending on the city you live in, the home’s proximity to amenities, and its size.",
        features : [
            "Rooms may be furnished or unfurnished",
            "Utilities are typically not included",
            "Meals are typically not included",
            "Great for meeting new people and making new friends"
        ],
        issues : [
            "Your housemates may be noisy, messy or refuse to do their share of housework",
            "Signing a lease with other people can be risky and complicated",
        ],
        illustration : "/images/illustrations/accommodation-illustrations-private-housemates@2x.png",
        links : {
            link1 : {
                text : "How to Successfully Apply For a Rental Property",
                url : "https://insiderguides.com.au/how-to-successfully-apply-for-a-rental-property/",
            },
            link2 : {
                text : "Eight Tips for Putting Together a Great Rental Application",
                url : "https://insiderguides.com.au/rental-application-tips/",
            } 
        }
    },

    purposeBuiltStudentAccommodation : {
        title : "Purpose Built Student Accommodation",
        excerpt : "Independent living, but with ultimate convenience and support! Purpose-built student accommodation is perfect for students looking for the best of both worlds. Here you’ll make new friends and enjoy top-tier amenities. ",
        costs : "Purpose-built student accommodation can cost anywhere from AU$200 to AU$500 per week. The exact expense will depend on the type of room, the included facilities, and the location. While utilities are included in this price, food is not. ",
        features : [
            "Fully furnished rooms",
            "Located on or close to the campus",
            "24 hour support",
            "Facilities (e.g., gym, pool, BBQ area, study areas",
            "Surrounded by other students (sense of community)"
        ],
        issues : [
            "Because you’ll be living with other students, it can get quite noisy, making it hard to study",
            "Shared spaces may also get quite messy, which can be challenging for some students",
            "You don’t get to choose who you live with, so there are no guarantees you’ll get along with everyone"
        ],
        illustration : "/images/illustrations/accommodation-illustrations-purpose-built@2x.png",
        links : {
            link1 : {
                text : "8 Tips on How to Make Lifelong Friends in Student Accommodation",
                url : "https://insiderguides.com.au/making-lifelong-friends-student-accommodation/",
            },
            link2 : {
                text : "10 Ways to Make Your Student Accommodation Feel Like Home",
                url : "https://insiderguides.com.au/10-ways-make-student-accommodation-feel-like-home/",
            } 
        }
    },


    homestay : {
        title : "Homestay",
        excerpt : "Become a member of the family with homestay accommodation. By living with a host family in their home, you’ll get that familial feeling while getting in touch with Australian culture.",
        costs : "Homestays generally cost between AU$250 to AU$350 per week. Your expenses will vary depending on whether your meals are included, as well as the location of the home.",
        features : [
            "Fully furnished room with access to communal spaces.",
            "Utilities included",
            "Meals can be included or not",
            "Inviting, familial environment"
        ],
        issues : [
            "Your host family could live far away from your education provider, which could create a long commute for you",
            "You will need to respect their rules and boundaries, which may not align with the lifestyle you want to lead",
        ],
        illustration : "/images/illustrations/accommodation-illustrations-homestay@2x.png",
        links : {
            link1 : {
                text : "Homestay",
                url : "https://insiderguides.com.au/homestay/",
            },
            link2 : {
                text : "Reasons to Consider Homestay",
                url : "https://insiderguides.com.au/5-reasons-choose-homestay/",
            } 
        }

    },

    colleges : {
        title : "Residential Colleges",
        excerpt : "If you are looking for the highest level of convenience, residential colleges have you covered. This accommodation style is also ideal for making new friends because you will be surrounded by fellow students",
        costs : "Residential colleges generally cost between AU$450 and AU$550 per week. Rooms in residential colleges tend to be more expensive due to their proximity to campus, as well as the extra perks you get (e.g., meals, room cleaning).",
        features : [
            "Fully furnished rooms",
            "Meals included",
            "Room cleaning",
            "Facilities (eg., gym, pool, BBQ area, study areas)",
            "Living with other students(ideal for making new friends)"
        ],
        issues : [
            "Residential colleges are expensive, so they may not be accessible to all students",
            "Because of the number of students living there, they can be noisy and messy",
        ],
        illustration : "/images/illustrations/accommodation-illustrations-Residential-Colleges@2x.png",
        links : {
            link1 : {
                text : "Residential Colleges",
                url : "https://insiderguides.com.au/residential-colleges/",
            },
            link2 : {
                text : "Moving to a New City: A Guide to Creating a Home Away from Home",
                url : "https://insiderguides.com.au/creating-a-home-away-from-home/",
            } 
        }
    }

}


    


export  {questions, outcomes, outcomeDetails}